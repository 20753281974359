<template>
  <div class>
    <!-- breadCrumb -->
    <v-breadcrumbs :items="breadcrumbList" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- breadCrumb -->
    <v-row>
      <v-col cols="12" md="4" class="title-col">
        <!-- <div class="title">
          <h1>template</h1>
        </div> -->
      </v-col>
    </v-row>

    <div>
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="12" md="12" class="info-list">
            <v-row>
              <v-col cols="12" md="4">
                <p class="value">
                  <strong class="custom-strong">{{ $t("Event") }}:</strong>
                  <v-text-field v-model.trim="item.title"></v-text-field>
                </p>
              </v-col>

              <v-col cols="12" md="4">
                <p class="value">
                  <strong class="custom-strong">{{ $t("Type") }}:</strong>
                </p>

                <p class="value">{{ item.message_type }}</p>
              </v-col>

              <v-col cols="12" md="4">
                <p class="value" style="margin-top: 20px">
                  <v-switch
                    v-model="item.status"
                    :label="$i18n.t('Active')"
                  ></v-switch>
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8">
            <v-select
              v-model="item.roles"
              :items="roles"
              item-text="name"
              item-value="id"
              solo
              multiple
            ></v-select>

            <v-alert border="right" colored-border type="error" elevation="2">
              {{
                $t(
                  "Don't change the codes after @ sign Codes should be between spaces"
                )
              }}
            </v-alert>
            <v-row>
              <v-col v-if="item.message_type == 'email'" cols="12" md="12">
                <p class="value">
                  <strong class="custom-strong"
                    >{{ $t("Subject in English") }}:</strong
                  >
                  <v-text-field
                    v-model.trim="item.subject"
                    :rules="[validationRules.required]"
                  ></v-text-field>
                </p>
                <p class="value" v-if="item.message_type == 'email'">
                  <strong class="custom-strong"
                    >{{ $t("Subject in Arabic") }}:</strong
                  >
                  <v-text-field
                    v-model.trim="item.subject_ar"
                    :rules="[validationRules.required]"
                  ></v-text-field>
                </p>
              </v-col>
              <v-col cols="12" md="12">
                <div class="value">
                  <strong class="custom-strong"
                    >{{ $t("English message") }}:</strong
                  >
                  <!-- <ckeditor
                    :editor="editor"
                    v-model="item.message"
                    :config="editorConfig"
                    id="editor"
                    class="ck-content"
                    :rules="[validationRules.required]"
                  ></ckeditor> -->
                  <vue-editor
                    v-model="item.message"
                    v-if="item.message_type == 'email'"
                    @blur="validateEmail(item.message, 'en')"
                  ></vue-editor>

                  <p class="red--text" v-if="!msgValid && checked">
                    {{ $t("This field is required") }}
                  </p>

                  <v-textarea
                    v-model="item.message"
                    v-if="
                      item.message_type == 'sms' ||
                        item.message_type == 'Notification'
                    "
                    :rules="[validationRules.required]"
                  ></v-textarea>
                </div>
                <p class="value">
                  <strong class="custom-strong"
                    >{{ $t("Arabic message") }}:</strong
                  >
                  <vue-editor
                    v-model="item.message_ar"
                    v-if="item.message_type == 'email'"
                    @blur="validateEmail(item.message_ar, 'ar')"
                  ></vue-editor>
                  <v-textarea
                    v-model="item.message_ar"
                    v-if="
                      item.message_type == 'sms' ||
                        item.message_type == 'Notification'
                    "
                    :rules="[validationRules.required]"
                  ></v-textarea>
                </p>
                <p class="red--text" v-if="!arabicMsgValid && checked">
                  {{ $t("This field is required") }}
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="4">
            <label>{{ $t("Codes") }}</label>

            <div class="item-form form-group">
              <v-chip class="ma-2" v-for="(code, index) in codes" :key="index">
                {{ code.name }}
              </v-chip>

              <!-- @click="insertCodeInMessage(code.name)" -->
            </div>
            <v-col cols="12" sm="12" md="12">
              <p>
                {{ $t("For payment") }} :
                <strong>
                  [link]/HyperPayChooseBrand / @student_code
                </strong>
              </p>
            </v-col>
          </v-col>
        </v-row>
        <!-- <v-divider></v-divider> -->

        <v-row>
          <v-col cols="12" md="12">
            <div class="btns text-right">
              <v-btn
                class="modal-btn-cancel ma-2"
                @click="goToviewMode"
                large
                >{{ $t("Cancel") }}</v-btn
              >

              <v-btn
                depressed
                color="#7297FF"
                @click="update()"
                large
                style="color: #fff"
                :loading="loading"
                :disabled="loading"
                >{{ $t("Update") }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-form>
    </div>

    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">{{ snackText }}</span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import axios from "axios";
// import ACL from "../acl";
import { validationMixin } from "../../mixins/validationMixin";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { VueEditor } from "vue2-editor";
// import { ImageDrop } from "quill-image-drop-module";
// import ImageResize from "quill-image-resize-module";

// import ImageEditor from "@ckeditor/ckeditor5-image";
export default {
  name: "EditNoticationTemplate",
  mixins: [validationMixin],
  components: {
    VueEditor,
  },
  data() {
    return {
      loading: false,
      msgValid: true,
      arabicMsgValid: true,
      checked: false,
      roles: [],
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 3000,
      item: [],
      codes: [],
      valid: false,
      validation_errors: [],
      // types: ["png", "jpeg"],
      // editor: ClassicEditor,
      // editorConfig: {
      //   // Enable the "Insert image" button in the toolbar.
      //   // toolbar: ["imageUpload"],

      //   ckfinder: {
      //     // Upload the images to the server using the CKFinder QuickUpload command.
      //     uploadUrl:
      //       this.getApiUrl + "/ckfinder/core/connector/php/connector.php",
      //   },
      //   // ckfinder: {
      //   //   // Upload the images to the server using the CKFinder QuickUpload command.
      //   //   // uploadUrl:
      //   //   //   "https://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json",
      //   // },
      //   // The configuration of the editor.
      //   // image: {
      //   //   uploude: this.types,
      //   // },
      // },
    };
  },

  methods: {
    validateEmail(value, lang) {
      console.log(value, lang);
      this.checked = true;
      if (lang == "en") {
        this.msgValid = !value ? false : true;
      } else {
        if (lang == "ar") {
          this.arabicMsgValid = !value ? false : true;
        }
      }
    },
    getRoles() {
      axios
        .get(this.getApiUrl + "/notifications/templete/getRoles", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.roles = response.data.data;
        });
    },
    getItem() {
      axios
        .get(
          this.getApiUrl +
            "/notifications/" +
            this.$router.currentRoute.params.id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.item = response.data.data;

          this.item.subject_ar = this.item.subject_ar.trim();
        });
    },

    update() {
      if (!this.valid) {
        this.validate();
      } else {
        // (this.arabicMsgValid == false && this.msgValid == false)
        this.validateEmail(this.item.message, "en");
        this.validateEmail(this.item.message_ar, "ar");

        if (this.msgValid && this.arabicMsgValid) {
          if (this.item.message != "") {
            this.loading = true;
            axios
              .post(
                this.getApiUrl +
                  "/notifications/" +
                  this.$router.currentRoute.params.id,
                { data: this.item },
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.token,
                  },
                }
              )
              .then((response) => {
                this.loading = false;
                if (response.data.status.error) {
                  this.snack = true;
                  this.snackColor = "red";
                  this.snackText = response.data.status.message;
                  this.validation_errors =
                    response.data.status.validation_errors;
                } else {
                  this.snack = true;
                  this.snackColor = "green";
                  this.snackText = response.data.status.message;
                  this.goToviewMode();
                }
              });
          }
        } else {
          this.snack = true;
          this.snackText = "This field is required";
          this.snackColor = "red";
        }
      }
    },
    geCodes() {
      axios
        .get(this.getApiUrl + "/notifications/templete/getAllCodes", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          // this.setPaginationParameters(response);
          this.codes = response.data.data;
        });
    },
    insertCodeInMessage(codename) {
      // console.log(choesCode);
      this.item.message += " " + codename + " ";
      this.item.message_ar += " " + codename + " ";
    },

    goToviewMode() {
      this.$router.push(
        {
          path: "/notificationTemplates",
        },
        () => {}
      );
    },
    onFoucs() {
      this.validation_errors = [];
      this.$refs.form.resetValidation();
    },
  },
  mounted() {
    this.getItem();
    this.geCodes();
    this.getRoles();
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
.cusom-chop {
  background: #8ba7f5;
  padding: 6px;
  border-radius: 15px;
  color: white;
  display: inline-block;
  // cursor: pointer;
}
</style>
